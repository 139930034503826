/*
Theme Name: Clem Pub Official Theme
Theme URI: www.aclab.it
Description: A theme for true beer lovers.
Version: 1.0.0
Author: Dr. Ing. Andrea Cola
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/

@font-face {
	font-family: oldStyle;
	src: url("../fonts/oldStyle.ttf");
}

@font-face {
	font-family: craft-beer;
	src: url("../fonts/craft_beer.otf");
}

html, body, #root {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	color: #555;
	font-size: 18px;
}

a, a:active, a:hover, a:focus {
	color: #555;
	font-style: none;
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.background-clem {
	background-image: url("../images/patterns/pattern.png");
	background-size: 50px;
	background-repeat: repeat;
	height: 100%;
}

.background-clem-dark {
	background-image: url("../images/patterns/pattern-black.png");
	background-size: 50px;
	background-repeat: repeat;
	overflow: scroll;
}

.background-clem-light {
	background-color: rgba(0, 0, 0, 0.2);
	color: black !important;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.text-clem {
	font-family: "craft-beer";
}

.text-clem-white {
	font-family: "craft-beer";
	color: #555;
}

.title {
	font-size: 65px;
}

.title-2 {
	font-size: 45px;
}

.title-3 {
	font-size: 30px;
}

.card {
	background-color: transparent;
	border-color: transparent;
}

.item-card {
	min-height: 200px;
	background-color: rgba(255, 255, 255, 0.2);
	box-shadow: 10;
	border: 1px solid #555;
	border-radius: 20px;
}

.can-scroll {
	overflow: scroll;
}

#login {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50%;
	background-image: url("../images/backgrounds/pacio.jpg");
}

.table {
	color: #555;
}

.item-card-override {
	background-color: transparent !important;
	margin: 0 0 10px !important;
	padding: 0 !important;
}

.item-card {
	overflow: hidden;
}

.item-card .title, .item-card .index {
	font-size: 30px;
	font-weight: 600;
}

.item-card .label {
	font-weight: 200;
}

.content-bold {
	font-weight: 800;
}

button, button:focus, button:active, select {
	color: #555 !important;
	border: 1px solid #555 !important;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 100px;
	background-color: transparent;
	padding: 5px 12px;
}

.item-card button:hover {
	color: #555;
	border-color: #555;
	background-color: transparent;
	outline: none;
}

.form-control-dark, .form-control-dark:focus, .form-control-dark:active {
	background-color: rgba(0, 0, 0, 0.2);
	color: #555;
}

.clem-message {
	position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 150px;
	text-align: center;
	font-size: 25px;
}

.popup {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
}

.popup img {
	max-width: 100%;
	max-height: 100%;	
}

.on-hover {
	cursor: pointer;
}

.wait-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.8);
	color: white;
}